import { PageProps } from 'gatsby';
import { ChannelData } from 'packages/innedit';
import { parse } from 'query-string';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import channel from '~/params/channel.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const PageChannelCreate: FC<PageProps & UserProps> = props => {
  const {
    location: { search },
    params: { id },
  } = props;

  const model = new ChannelData({
    espaceId: id,
    params: channel,
  });

  let params;
  if (search) {
    params = parse(search);
  }

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          initializeData={params}
          model={model}
          type="create"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageChannelCreate, 'admin');
